import API from "@/plugins/axios-method";
import {serverUrl} from "@/helpers/constant/serverUrl";

export const exportToExcel =  (endPoint) => {
    return API.get(endPoint)
        .then(response => {
            const {link} = response.data;
            const downloadTag = document.createElement('a');
            downloadTag.target = '_blank';
            downloadTag.href = serverUrl + '/' + link;
            document.body.appendChild(downloadTag);
            downloadTag.click();
            document.body.removeChild(downloadTag);

        })
}